















import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
import TwoFaAuthenticatorCard from './TwoFaAuthenticatorCard.vue';
import LoginPasswordCard from './LoginPasswordCard.vue';
import ApiKeyCard from './ApiKeyCard.vue';

@Component({
  components: {
    TwoFaAuthenticatorCard,
    LoginPasswordCard,
    ApiKeyCard,
  }
})
export default class SettingsPage extends Vue {
}
