



























import { Component, Mixins, Vue } from 'vue-property-decorator';
import { changePassword } from '@/apis/password.api';
import {
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';
import FormMixin from '@/mixins/form';

@Component({
  props: {
    onCancel: {
      type: Function,
      default: () => {
        return {};
      },
    },
  },
})
export default class ChangePasswordForm extends Mixins(FormMixin) {
  errorText = '';
  onCancel: Function;

  form = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    fa_code: '',
  };

  errors = {
    changing: '',
  };

  loadings = {
    changing: false,
  };

  @errorCatchHandler('errors.changing', '', 'form')
  @loadings('loadings.changing')
  submit() {
    return changePassword({
      current_password: this.form.currentPassword,
      new_password: this.form.newPassword,
      fa_code: this.form.fa_code,
    }).then(() => {
      this.onCancel();
    })
    .finally(() => {
      this.form.currentPassword = '';
      this.form.newPassword = '';
      this.form.confirmPassword = '';
      this.clearVeeErros();
    });
  }

  cancel() {
    this.onCancel();
  }
}
