



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import { Watch } from 'vue-class-component';

@Component({
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ok: {
      type: Function,
      default: () => {
        return {};
      },
    },
    cancel: {
      type: Function,
      default: () => {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    }
  },
})
export default class Modal extends Vue {
  isShowing = false;
  settings: any;
  value = false;

  show() {
    this.isShowing = true;
  }

  hide() {
    this.isShowing = false;
  }

  onChangeShowing() {
    if (!this.isShowing && this.settings.onHidden) {
      this.settings.onHidden();
    }
  }

  @Watch('value')
  onValueChange(newVal) {
    if (newVal !== this.isShowing) {
      this.isShowing = newVal;
    }
  }

  @Watch('isShowing')
  onIsShowIngChange(newVal) {
    if (newVal !== this.value) {
      this.$emit('input', newVal);
    }
  }
}
