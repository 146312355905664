import { http } from './http';

export function generateTwoFa(): Promise<any> {
  return http
    .callApi({
      resource: 'twoFa.generate',
    })
    .then(res => res.data);
}

export function enableTwoFa(password: string, faCode: string): Promise<any> {
  return http
    .callApi({
      resource: 'twoFa.enable',
      data: {
        body: {
          password,
          fa_code: faCode,
        },
      },
    })
    .then(res => res.data);
}

export function disableTwoFa(password: string, faCode: string): Promise<any> {
  return http
    .callApi({
      resource: 'twoFa.disable',
      data: {
        body: {
          password,
          fa_code: faCode,
        },
      },
    })
    .then(res => res.data);
}

export function verifyTwoFa(faCode: string): Promise<any> {
  return http
    .callApi({
      resource: 'twoFa.verify',
      data: {
        body: {
          fa_code: faCode,
        },
      },
    })
    .then(res => res.data);
}