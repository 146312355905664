




















import { Component, Prop, Vue } from 'vue-property-decorator';
import Enable2FaForm from './components/Enable2FaForm.vue';
import Disable2FaForm from './components/Disable2FaForm.vue';
import { Action, Getter, State } from 'vuex-class';
import { AccountDetail } from '@/dtos/Account';

@Component({
  components: {
    Enable2FaForm,
    Disable2FaForm,
  }
})
export default class TwoFaAuthenticator extends Vue {
  @State(e => e.account && e.account.detail || null)
  account!: AccountDetail;

  showForm = false;

  get title(): string {
    return this.account.enable_2fa ? 'Disable Two-Factor Authentication' : 'Enable Two-Factor Authentication';
  }

  hideForm() {
    this.showForm = false;
  }
}
