

















































































import { Component, Prop, Vue, Provide, Watch } from 'vue-property-decorator';
import { mounted, loadings, debounce } from '@/utils/decorators/VueTimmer';
import {
  listPlan,
  accountPlan,
  previewPlan,
  updatePlan,
} from '@/apis/plan.api';
import {
  PlanDetail,
  AccountPlanDetail,
  Order,
  PreviewOrder,
  AccountDetail,
} from '@/dtos/Account';
import { keyBy, fromPairs, mapValues, forOwn, get as lodashGet, range as lodashRange } from 'lodash';
import { Debounce } from 'lodash-decorators';
import { Action, Getter, State } from 'vuex-class';

@Component({
})
export default class PricingCalculation extends Vue {
  @Getter('account/pendingPlan')
  pendingPlan: any;

  @State(e => e.account && e.account.detail || null)
  account!: AccountDetail;

  @Provide()
  plans: Record<string, PlanDetail> = {};

  @Provide()
  accountPlan: Record<string, AccountPlanDetail> = {};

  @Provide()
  customize: Record<string, number> = {};

  @Provide()
  previewOrder: PreviewOrder | null = null;

  @Provide()
  expandMonth = 0;

  @Provide()
  loadings = {
    preview: false,
    updating: false,
  };

  get months() {
    let min = this.minMonthExpand;
    let months = lodashRange(min, 13);
    return months;
  }

  get minMonthExpand() {
    let min = (this.account.plan_expire_at <= new Date().getTime())  ? 1 : 0;
    return min;
  }

  get allPlans(): Record<
    string,
    PlanDetail & AccountPlanDetail & { min_visible: number }
  > {
    let accountPlan = this.accountPlan;
    return mapValues(this.plans, (value, coin) => {
      let minVisible =
        lodashGet(accountPlan, `${coin}.purchase_status`, 'free') === 'free'
          ? 0
          : accountPlan[coin].max_quota;

      return {
        ...(accountPlan[coin] || {}),
        ...value,
        // min: value.min_quota,
        // max: value.max_quota,
        min_visible: minVisible,
      };
    });
  }

  get expenseTimeAsMs() {
    return this.expandMonth * 30 * 1440 * 60000;
  }

  get expandedPlans() {
    let expandedPlans: any = {};
    let allPlans = this.allPlans;

    forOwn(this.customize, (value, coin) => {
      let v = +value - allPlans[coin].min_visible;
      expandedPlans[coin] = Math.max(0, v);
    });

    return expandedPlans;
  }

  @mounted
  getPlanList() {
    return listPlan().then(e => {
      this.plans = keyBy(e, 'currency_code');
    });
  }

  @mounted
  getAccountPlan() {
    accountPlan().then(e => (this.accountPlan = keyBy(e, 'currency')));
  }

  mounted() {
    this.expandMonth = this.minMonthExpand;
  }

  @Watch('allPlans')
  onPlanChange() {
    forOwn(this.allPlans, (plan, coin) => {
      Vue.set(this.customize, coin, plan.min_visible);
    });
  }

  @Watch('customize', { deep: true })
  onCustomizeChange() {
    this.$nextTick(() => {
      forOwn(this.allPlans, (plan, coin) => {
        if (this.customize[coin] < plan.min_visible) {
          Vue.set(this.customize, coin, plan.min_visible);
        }
      });
    });
  }

  @Watch('expandedPlans', { deep: true })
  @Watch('expenseTimeAsMs')
  @debounce(300)
  @loadings('loadings.preview')
  previewPlan() {
    return previewPlan(this.expandedPlans, this.expenseTimeAsMs)
      .then(e => {
        this.previewOrder = e;
      })
      .then(e => new Promise(r => setTimeout(r, 500)))
      .catch(() => {
        this.previewOrder = null;
      });
  }

  @loadings('loadings.updating')
  updatePlan() {
    return updatePlan(this.expandedPlans, this.expenseTimeAsMs)
      .then(() => this.updatePendingPlan())
      .then(order =>
        this.$router.replace({
          name: 'deposit',
          query: { payment_id: order.payment.payment_id },
        }),
      );
  }

  @Action('account/getPendingPlan')
  async updatePendingPlan(): Promise<Order> {
    return null;
  }
}
