



































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { generateTwoFa, enableTwoFa } from '@/apis/twoFa.api';
import { mounted } from '@/utils/decorators/VueTimmer';
import FormMixin from '@/mixins/form';
import {
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';
import { Message } from 'element-ui';

@Component
export default class Enable2FaForm extends Mixins(FormMixin) {
  step = 1;
  form = {
    password: '',
    fa_code: '',
  };

  generatedTwoFa = {
    qr_code_link: '',
    secret: '',
  };

  errors = {
    submiting: '',
  };

  loadings = {
    submiting: '',
  };

  @mounted
  generateTwoFa() {
    return generateTwoFa().then(data => {
      this.generatedTwoFa = data;
    });
  }

  mounted() {}

  next() {
    this.step++;
  }

  back() {
    this.step--;
  }

  @errorCatchHandler('errors.submiting', '', 'form')
  @loadings('loadings.submiting')
  submit() {
    let form = this.form;
    return enableTwoFa(form.password, form.fa_code)
      .then(() => {
        Message.success('Enable Two-Factor Authentication Successfully');
        this.$store.dispatch('account/tryGetProfile');
        this.$emit('success');
      })
      .finally(() => {
        this.form.password = '';
        this.form.fa_code = '';
        this.clearVeeErros();
      });
  }
}
