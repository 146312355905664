


































import { Component, Prop, Vue, Provide, Watch } from 'vue-property-decorator';
import PricingCalculation from '@/components/shared/PricingCalculation.vue';

@Component({
  components: {
    PricingCalculation
  },
})
export default class PlanPage extends Vue {
  
}
