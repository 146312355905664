import { http } from './http';

export function forgotPassword({ email }): Promise<any> {
  return http
    .callApi({
      resource: "password.forgot",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          email
        }
      }
    }).then(e => e.data)
}
export function resetPassword({ token, password }): Promise<any> {
  return http
    .callApi({
      resource: "password.reset",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          token, password
        }
      }
    }).then(e => e.data)
}

export function changePassword({ current_password, new_password, fa_code = '' }): Promise<any> {
  return http
    .callApi({
      resource: "password.change",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: { current_password, new_password, fa_code }
      }
    }).then(e => e.data)
}
