

















import { Component, Mixins, Vue } from 'vue-property-decorator';
import FormMixin from '@/mixins/form';
import {
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';
import { Message } from 'element-ui';
import { disableTwoFa } from '@/apis/twoFa.api';

@Component
export default class Disable2FaForm extends Mixins(FormMixin) {
  form = {
    password: '',
    fa_code: '',
  };

  errors = {
    submiting: '',
  };

  loadings = {
    submiting: '',
  };

  @errorCatchHandler('errors.submiting', '', 'form')
  @loadings('loadings.submiting')
  submit() {
    let form = this.form;
    return disableTwoFa(form.password, form.fa_code)
      .then(() => {
        Message.success('Disable Two-Factor Authentication Successfully');
        this.$store.dispatch('account/tryGetProfile');
        this.$emit('success');
      })
      .finally(() => {
        this.form.password = '';
        this.form.fa_code = '';
        this.clearVeeErros();
      });
  }
}
