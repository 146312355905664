


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { AccountDetail } from '@/dtos/Account';
import { Message } from 'element-ui';

@Component
export default class ApiKeyCard extends Vue {
  @State(e => e.account && e.account.detail || null)
  account!: AccountDetail;

  enable() {
    if (!this.account.enable_2fa) {
      this.$popupError({
        data: 'Please Enable Two-Factor Authentication First',
      });
      return;
    }

  }
}
