import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MyMixin extends Vue {
  isAutoFill = false;

  get isFormValid() {
    const veeErrors = this.veeErrors;
    const veeFields = this.veeFields;
    const isAutoFill = this.isAutoFill;

    const isOk =
      !veeErrors.any() &&
      (_(veeFields)
        .mapValues('validated')
        .values()
        .compact()
        .value().length === _.keys(veeFields).length ||
        isAutoFill);

    return isOk;
  }

  get isFormDirty() {
    const veeErrors = this.veeErrors;
    const veeFields = this.veeFields;
    return Object.keys(veeFields).some(key => veeFields[key].dirty);
  }

  onAutoFill() {
    this.isAutoFill = true;
  }

  clearVeeErros() {
    this.isAutoFill = false;
    this.$validator.pause();
    this.$nextTick(() => {
      this.$validator.fields.items.forEach(field => (field as any).reset());
      this.$validator.resume();
    });
  }
}
