

















import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/shared/Modal.vue';
import ChangePasswordForm from './components/ChangePasswordForm.vue';

@Component({
  components: {
    Modal,
    ChangePasswordForm,
  }
})
export default class LoginPasswordCard extends Vue {
  showForm = false

  hideForm() {
    this.showForm = false;
  }
}
